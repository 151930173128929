(function () {
  if (JSON && !JSON.parseWithDate) {
    
    JSON.parseWithoutDate = JSON.parse; //Store the original JSON.parse function
    JSON.stringifyWithoutDate = JSON.stringify; //Store the original JSON.stringify function
    var reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)([zZ]|([+\-])(\d\d):?(\d\d))?$/;
    var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

    JSON.parseWithDate = function (json) {
      /// <summary>  
      /// parses a JSON string and turns ISO or MSAJAX date strings  
      /// into native JS date objects  
      /// </summary>      
      /// <param name="json" type="var">json with dates to parse</param>          
      /// </param>  
      /// <returns type="value, array or object" />  
      try {
        var res = JSON.parseWithoutDate(json,
          function (key, value) {
            if (typeof value === 'string') {
              var a = reISO.exec(value);
              if (a) {
                var d = new Date(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]);
                // 2019-11-13 Maurice: in local storage worden de datetime objecten als UTC ISO geserialized.. 
                // bij het deserializen even terug naar de huidige tijdzone converteren.
                if (a[7] && a[7]==='Z') { 
                  d.setHours(d.getHours() - (d.getTimezoneOffset()/60));
                  // console.info(`UTC ISO date '${key}' ${value} changed to ${d}`);
                }
                return d;
              }
              a = reMsAjax.exec(value);
              if (a) {
                var b = a[1].split(/[-+,.]/);
                return new Date(b[0] ? +b[0] : 0 - +b[1]);
              }
            }
            return value;
          });
        return res;
      } catch (e) {
        // orignal error thrown has no error message so rethrow with message  
        throw new Error("JSON content could not be parsed");
        return null;
      }
    };
    
    //Make Date parsing the default
    JSON.parse = JSON.parseWithDate;
    //JSON.stringify = JSON.stringifyLocalDateToUTC;
  }
})();




Array.prototype.containsObjectWithPropertyValue = function (property, value) {
  for (var i = 0; i < this.length; i++) {
    if (this[i][property] === value) {
      return true;
    }
  }
  return false;
};




// Removes an element from an array.
// String value: the value to search and remove.
// return: an array with the removed element; false otherwise.
Array.prototype.remove = function (value) {
  var idx = this.indexOf(value);
  if (idx != -1) {
    return this.splice(idx, 1); // The second parameter is the number of elements to remove.
  }
  return false;
}
